<template>
  <div class="page-container orders-list">
    <div class="page-title-container">
      <h1 class="page-title">Pedidos</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-100">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <div
                class="d-flex align-items-center justify-content-between filter"
                @click="showFilters = !showFilters"
              >
                <FiltersIcon />
                <div>Filtrar pedidos</div>
              </div>

              <div>
                <button
                  type="button"
                  class="button-tooltip m-0 d-md-none"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <RefreshTableIcon />
                </button>
                <button
                  type="button"
                  class="button-tooltip d-md-none mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadOrders()"
                >
                  <DownloadTableIcon />
                </button>
              </div>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center mt-2 mt-lg-0">
            <button
              type="button"
              class="button-tooltip m-0 d-none d-md-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>
            <button
              type="button"
              class="button-tooltip d-none d-md-block"
              v-b-tooltip.hover
              title="Descargar"
              @click="downloadOrders()"
            >
              <DownloadTableIcon />
            </button>
            <SearchBar
              placeholder="Buscar por nombre de cliente o ID pedido"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <table class="custom-table w-100 d-xl-none">
          <thead>
            <tr>
              <th class="text-left px-4">
                ID Pedido
                <span class="d-inline-block ml-3">Nombre del cliente</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="px-4">
                <div class="d-flex justify-content-between align-items-start">
                  <div>
                    <p class="my-0">
                      <span>{{ o.correlationId }}</span>
                      <span class="d-inline-block ml-3">{{
                        `${o.customerFirstName} ${o.customerLastName}`
                      }}</span>
                    </p>

                    <div class="table-mobile-options mt-2">
                      <router-link
                        :to="{ name: 'order-details', params: { id: o.id } }"
                      >
                        Ver datos
                      </router-link>

                      <a
                        href=""
                        class="ml-2"
                        @click.prevent="openChangeStatus(o)"
                        v-if="showModalOrderStatus(o)"
                        >Cambiar estado</a
                      >
                    </div>
                  </div>

                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>

                <div class="pt-3" v-if="o.showMobileData">
                  <ul class="list-unstyled p-0">
                    <li class="mb-1">
                      <span class="font-weight-bold">Fecha de pedido:</span>
                      <span
                        class="d-inline-block ml-1"
                        v-localDate="o.createdAt"
                      ></span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Fecha de entrega:</span>
                      <span
                        class="d-inline-block ml-1"
                        v-localDate="o.deliverDate"
                      ></span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Método de envío:</span>
                      <span class="d-inline-block ml-1">{{
                        getDeliveryType(o.deliveryType)
                      }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Método de pago:</span>
                      <span class="d-inline-block ml-1">{{
                        getPaymentLabel(o.paymentMethodId)
                      }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Importe:</span>
                      <span
                        class="d-inline-block ml-1"
                        v-decimal:pen="o.totalPlusDelivery"
                      ></span>
                    </li>
                    <li>
                      <span class="font-weight-bold">Estado:</span>
                      <span
                        class="d-inline-block ml-1"
                        :class="`badge ${getStatus(o.status).color}`"
                        >{{ getStatus(o.status).label }}</span
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="px-3">ID Pedido</th>
              <th class="px-3">Fecha de pedido</th>
              <th class="px-3">
                Fecha <br />
                de entrega
              </th>
              <th class="px-3">Nombre del cliente</th>
              <th class="px-3">
                Método <br />
                de envío
              </th>
              <th class="px-3">
                Método <br />
                de pago
              </th>
              <th class="px-3">Importe</th>
              <!-- <th>
                Dirección <br />
                confirmada
              </th> -->
              <!-- <th>ERP ID</th> -->
              <th class="px-3">Estado</th>
              <th class="px-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">
                <span>{{ o.correlationId }}</span>
              </td>
              <td class="text-center">
                <span v-localDate="o.createdAt"></span>
              </td>
              <td class="text-center">
                <span v-localDate="o.deliverDate"></span>
              </td>
              <td class="text-center">
                {{ `${o.customerFirstName} ${o.customerLastName}` }}
              </td>
              <td class="text-center text-uppercase pr-md-3">
                <span>{{ getDeliveryType(o.deliveryType) }}</span>
              </td>
              <td class="text-center text-uppercase">
                <span>{{ getPaymentLabel(o.paymentMethodId) }}</span>
              </td>
              <td class="text-center" v-decimal:pen="o.totalPlusDelivery"></td>
              <!-- <td class="text-center">{{ o.confirmedAddress }}</td> -->
              <!-- <td class="text-center">{{ o.erpId }}</td> -->
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Ver datos"
                      :to="{ name: 'order-details', params: { id: o.id } }"
                    />
                    <DropdownItem
                      text="Cambiar estado"
                      @click="openChangeStatus(o)"
                      v-if="showModalOrderStatus(o)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ModalOrderStatus
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :selectedOption="selectedOption"
      @updated="updateStatus"
      v-if="selectedOption"
    />
  </div>
</template>

<script>
import moment from "moment";

import {
  Alert,
  CloseBorderRoundedIcon,
  ConfirmationModalMixin,
  Constants,
  DownloadTableIcon,
  DropdownItem,
  DropdownTable,
  FilterMenu,
  FilterMixin,
  FiltersIcon,
  PaginationMixin,
  RefreshTableIcon,
  SearchBar,
  Util,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import fileDownload from "js-file-download";

import { OrderService } from "@/core/services";

import ModalOrderStatus from "../Components/ModalOrderStatus";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
    ModalOrderStatus,
    DownloadTableIcon,
  },
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.OrdersStatus.PENDING,
            LocalConstants.OrdersStatus.PAYMENT_CONFIRMED,
            LocalConstants.OrdersStatus.DELIVERED,
            LocalConstants.OrdersStatus.CANCELLED,
            LocalConstants.OrdersStatus.REJECTED
          ],
        },
        {
          label: "Método de pago",
          key: "paymentMethodIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Fecha de pedido",
          key: "createdAt",
          type: Constants.TypeFilters.DATE,
        },
        {
          label: "Fecha de entrega",
          key: "deliverAt",
          type: Constants.TypeFilters.DATE,
        },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        // Asignar filtros
        let filters = {
          ...this.filters,
        };

        if (filters.createdAt) {
          filters.createdAtStart = moment(filters.createdAt.startDate).format(
            "YYYY-MM-DD"
          );
          filters.createdAtEnd = moment(filters.createdAt.endDate).format(
            "YYYY-MM-DD"
          );
        }

        if (filters.deliverAt) {
          filters.deliverAtStart = moment(filters.deliverAt.startDate).format(
            "YYYY-MM-DD"
          );
          filters.deliverAtEnd = moment(filters.deliverAt.endDate).format(
            "YYYY-MM-DD"
          );
        }
        // --

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          search: this.search,
          ...filters,
        };
        const response = await OrderService.list(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.OrdersStatus);
    },
    getPaymentLabel(id) {
      let label = "";

      let item = this.filterOptions[1].options.find((o) => o.key == id);

      if (item) {
        label = item.label;
      }

      return label;
    },
    async changeStatus() {},
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado del pedido?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    async setPaymentMethods() {
      this.filterOptions[1].options = Object.values(
        LocalConstants.PaymentMethods
      );
    },
    updateStatus({ id, status }) {
      let item = this.list.find((i) => i.id == id);

      if (item) {
        item.status = status;
      }
    },
    getDeliveryType(key) {
      let type = "-";

      if (key) {
        type = Util.searchValue(key, LocalConstants.ShippingTypes).label;
      }

      return type;
    },
    showModalOrderStatus(order) {
      let status = true;

      if (
        order.status == LocalConstants.OrdersStatus.CANCELLED.key ||
        order.status == LocalConstants.OrdersStatus.REJECTED.key
      ) {
        status = false;
      }

      return status;
    },
    async downloadOrders() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          search: this.search,
          ...this.filters,
        };
        const response = await OrderService.downloadOrderList(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();

    // Asignar métodos de pago activos para filtro
    this.setPaymentMethods();
  },
};
</script>

<style lang="stylus"></style>
