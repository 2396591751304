<template>
    <div class="filter-delivery-list relative">
        <div class="d-flex align-items-center justify-content-between filter" @click="showOptions = !showOptions">
            <FiltersIcon />
            <div>Filtrar Ubigeo</div>
        </div>

        <form class="filter-options bg-white mt-1" @submit.prevent="submit()" v-if="showOptions">
            <div class="filter-content custom-form">
                <div>
                    <label for="address">Departamento</label>
                    <FormSelect id="departments" v-model="form.departmentId" :items="departments"
                        defaultOption="Seleccione" />
                </div>

                <div>
                    <label for="address">Provincia</label>
                    <FormSelect id="departments" v-model="form.provinceId" :items="provinces"
                        defaultOption="Seleccione" />
                </div>

                <div>
                    <label for="address">Distrito</label>
                    <FormSelect id="departments" v-model="form.districtId" :items="districts"
                        defaultOption="Seleccione" />
                </div>
            </div>
            <div class="filter-footer d-flex justify-content-end">
                <button class="button button-light" type="button" @click="showOptions = false">
                    Cancelar
                </button>
                <button class="button button-primary ml-2" type="submit">
                    Aplicar
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { FiltersIcon, FormSelect } from 'wize-admin'

import { UbigeoService } from '@/core/services'

export default {
    name: 'FilterDeliveryList',
    data() {
        return {
            showOptions: false,
            ubigeos: [],
            departments: [],
            provinces: [],
            districts: [],
            form: {
                departmentId: null,
                provinceId: null,
                districtId: null
            }
        }
    },
    async created() {
        const data = await UbigeoService.getUbigeos();
        this.ubigeos = data.payload.map((o) => {
            return { key: o.id, label: o.description, parentId: o.parentId };
        });

        // Separar departamentos según la propiedad "parentId"
        this.departments = this.ubigeos.filter((o) => !o.parentId);
    },
    props: {
      deletedOptions: Object
    },
    watch: {
        "form.departmentId"(newVal) {
            if (newVal) {
                this.provinces = this.ubigeos.filter((o) => o.parentId === newVal);
            } else {
                this.provinces = [];
            }
            this.form.districtId = null;
            this.form.provinceId = null;
        },
        "form.provinceId"(newVal) {
            if (newVal) {
                this.districts = this.ubigeos.filter((o) => o.parentId === newVal);
            } else {
                this.districts = [];
            }
            this.form.districtId = null;
        },
        deletedOptions: function(val) {
          if(val) {
            this.form = {...val}
          }
        }
    },
    components: {
        FiltersIcon,
        FormSelect
    },
    methods: {
        submit() {
            this.$emit('success', {
                ...this.form
            })

            this.showOptions = false
        }
    }
}
</script>

<style lang="stylus">
.filter-delivery-list
    .filter-options
        width: 476px
        border-radius: 8px
        box-shadow: 0px 3px 5px rgba(0,0,0,0.15)
        position: absolute
        left: 10px
        z-index: 50

    .filter-content
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 16px
        padding: 16px

    .filter-footer
        border: 0.4px solid color-gray-4
        padding: 12px 16px
</style>
