import BiolinkView from "../BiolinkView.vue";
import GeneralInformation from "../Pages/GeneralInformation.vue";

const routes = [
  {
    path: '/biolink',
    component: BiolinkView,
    plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
    children: [
      {
        path: '',
        name: 'biolink',
        plan: ['PRO', 'BUSINESS', 'ENTERPRISE'],
        component: GeneralInformation,
      },
    ]
  }
]

export default routes
