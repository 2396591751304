<template>
  <aside class="page-sidebar-mobile-container">
    <div
      class="page-sidebar-mobile-overlay"
      :class="{ active: show }"
      @click="toggleMenu(false)"
    ></div>

    <div
      class="page-sidebar-mobile d-flex flex-column d-lg-none"
      :class="{ active: show }"
    >
      <div class="header d-flex align-items-center">
        <b-avatar
          class="avatar"
          src="https://assets.admin.wize.pe/images/dropdown-user-avatar.svg"
        >
        </b-avatar>
        <div class="d-flex flex-column pl-3">
          <span class="user-name">{{ username }}</span>
          <span class="email d-inline-block mt-1">{{ user.email }}</span>
        </div>
      </div>

      <div class="grid mt-2 px-2">
        <router-link
          :to="{ name: 'dashboard' }"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name == 'dashboard' }"
        >
          <HomeIcon class="link-icon fill-white ml-1" />
          Portada
        </router-link>

        <router-link
          :to="{ name: 'orders-list' }"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active: $route.name == 'orders-list',
          }"
          v-if="$can('manage', 'orders')"
        >
          <OrderIcon class="link-icon fill-white ml-1" />
          Pedidos
        </router-link>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active:
              selection == 'clients-list' || $route.name == 'clients-list',
          }"
          @click="setSelection('clients-list')"
          v-if="$can('manage', 'clients-list')"
        >
          <UsersIcon class="link-icon fill-white ml-1" />
          Clientes
        </button>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active: isProductsRoute,
          }"
          @click="setSelection('products')"
          v-if="$can('manage', 'products')"
        >
          <ShoppingIcon class="link-icon fill-white ml-1" />
          Productos
        </button>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: isConfigRoute }"
          @click="setSelection('config')"
          v-if="$can('manage', 'config')"
        >
          <PencilIcon class="link-icon fill-white ml-1" />
          Mantenimiento
        </button>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active: isAppearanceRoute,
          }"
          @click="setSelection('appearance')"
          v-if="$can('manage', 'appearance')"
        >
          <AppearanceIcon class="link-icon fill-white ml-1" />
          Apariencia
        </button>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: isToolsRoute }"
          @click="setSelection('tools')"
          v-if="$can('manage', 'tools')"
        >
          <ToolsIcon class="link-icon fill-white ml-1" />
          Herramientas
        </button>

        <router-link
          :to="{ name: 'biolink' }"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active: selection == 'biolink' || $route.name == 'biolink',
          }"
          v-if="$can('manage', 'biolink')"
        >
          <BiolinkIcon class="link-icon fill-white ml-1" />
          Biolink
        </router-link>
      </div>

      <div class="menu-list-container">
        <ul class="menu-list list-unstyled" v-if="selection">
          <li v-for="item in getMenuItems" :key="item.text">
            <router-link
              :to="item.route"
              exact-active-class="active"
              class="menu-subitem text-decoration-none"
              v-if="$can('manage', item.manage)"
              ><BulletIcon class="fill-white mr-3" />{{
                item.text
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <router-link
        :to="{ name: 'general-configuration' }"
        class="menu-option d-flex align-items-center"
        :class="{ active: $route.name == 'general-configuration' }"
        v-if="$can('manage', 'general-configuration')"
      >
        <ConfigIcon class="fill-white mr-4" />

        <span class="sidebar-item-text">Configuración general</span>
      </router-link>

      <div class="menu-option classic plan d-flex align-items-center">
        <span class="sidebar-item-text">{{ getPlan().label }}</span>
      </div>

      <button
        type="button"
        class="menu-option d-flex align-items-center"
        @click="logout()"
      >
        <LogoutIcon class="fill-white mr-4" />

        <span class="sidebar-item-text">Cerrar sesión</span>
      </button>

      <div
        class="menu-option classic version d-flex justify-content-between align-items-center"
      >
        <span class="text">V{{ version }} {{ year }}</span>
        <p class="my-0">
          Forjado por <a href="https://wize.pe" target="_blank">Wize</a>
        </p>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { Helpers, Constants as LocalConstants } from "@/core/utils";

import {
  HomeIcon,
  ConfigIcon,
  OrderIcon,
  PencilIcon,
  ShoppingIcon,
  UsersIcon,
  BiolinkIcon,
  AppearanceIcon,
  ToolsIcon,
  BulletIcon,
  LogoutIcon,
} from "wize-admin";

export default {
  name: "PageSidebarMobile",
  data() {
    return {
      LocalConstants,
      version: process.env.PACKAGE_VERSION,
      year: new Date().getFullYear(),
      selection: "",
      menuList: [
        {
          key: "home",
        },
        {
          key: "orders",
          list: [
            {
              route: { name: "orders-list" },
              text: "Pedidos",
            },
          ],
        },
        {
          key: "clients-list",
          list: [
            {
              route: { name: "clients-list" },
              manage: "clients-list",
              text: "Lista",
            },
          ],
        },
        {
          key: "products",
          list: [
            {
              route: { name: "products-list" },
              manage: "products-list",
              text: "Lista",
            },
            {
              route: { name: "load-products" },
              manage: "load-products",
              text: "Cargar productos",
            },
            {
              route: { name: "update-stock" },
              manage: "update-stock",
              text: "Actualizar stock",
            },
            {
              route: { name: "update-prices" },
              manage: "update-prices",
              text: "Actualizar precios",
            },
            {
              route: { name: "quotes" },
              manage: "quotes",
              text: "Cotizador",
            },
          ],
        },
        {
          key: "config",
          list: [
            {
              route: { name: "config-users-list" },
              manage: "config-users-list",
              text: "Usuarios",
            },
            {
              route: { name: "config-categories-list" },
              manage: "config-categories-list",
              text: "Categorías",
            },
            {
              route: { name: "config-attributes-list" },
              manage: "config-attributes-list",
              text: "Atributos",
            },
            {
              route: { name: "config-providers-list" },
              manage: "config-providers-list",
              text: "Proveedores",
            },
            {
              route: { name: "config-store-list" },
              manage: "config-store-list",
              text: "Locales",
            },
            {
              route: { name: "config-banners-list" },
              manage: "config-banners-list",
              text: "Banners",
            },
            {
              route: { name: "config-payment-methods" },
              manage: "config-payment-methods",
              text: "Métodos de pago",
            },
            {
              route: { name: "config-delivery-list" },
              manage: "config-delivery-list",
              text: "Entregas y envíos",
            },
            {
              route: { name: "config-pages-list" },
              manage: "config-pages-list",
              text: "Páginas",
            },
            {
              route: { name: "config-menu-settings" },
              manage: "config-menu-settings",
              text: "Configuración de menú",
            },
          ],
        },
        {
          key: "appearance",
          list: [
            {
              route: { name: "appearance-menus" },
              manage: "appearance-menus",
              text: "Menús",
            },
          ],
        },
        {
          key: "tools",
          list: [
            {
              route: { name: "tools-quotes-list" },
              manage: "tools-quotes-list",
              text: "Cotizador",
            },
            {
              route: { name: "tools-redirections-list" },
              manage: "tools-redirections-list",
              text: "Redirecciones",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.setSelection(this.$route.name);

    if (this.isProductsRoute) {
      this.setSelection("products");
    }

    if (this.isAppearanceRoute) {
      this.setSelection("appearance");
    }

    if (this.isConfigRoute) {
      this.setSelection("config");
    }

    if (this.isToolsRoute) {
      this.setSelection("tools");
    }
  },
  props: {
    show: Boolean,
  },
  components: {
    HomeIcon,
    ConfigIcon,
    OrderIcon,
    PencilIcon,
    ShoppingIcon,
    UsersIcon,
    BiolinkIcon,
    AppearanceIcon,
    ToolsIcon,
    BulletIcon,
    LogoutIcon,
  },
  watch: {
    "$route.name": function () {
      if (this.currentDevice != "desktop") {
        this.toggleMenu(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu",
    }),
    setSelection(key) {
      this.selection = key;
    },
    getPlan() {
      return LocalConstants.Plans.find(
        (item) => item.key == this.getCurrentPlan
      );
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isMenuOpen: "app/isMenuOpen",
      getCurrentPlan: "app/getCurrentPlan",
      currentDevice: "app/getCurrentDevice",
    }),
    username: function () {
      return Helpers.capitalizeName(this.user.name);
    },
    getMenuItems() {
      let item = this.menuList.find((item) => item.key == this.selection);
      return item ? item.list : [];
    },
    isProductsRoute() {
      let routes = ["products", "update-stock", "update-prices"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
    isAppearanceRoute() {
      let routes = ["appearance"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
    isConfigRoute() {
      let routes = ["config"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
    isToolsRoute() {
      let routes = ["tools"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
  },
};
</script>

<style lang="stylus">
@import "../../styles/components/layout/page-sidebar-mobile.styl"
</style>
